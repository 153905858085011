<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card v-if="$route.params.langId">
      <div class="mb-3 w-100 d-flex align-items-center">
        <h1>Create New Menu</h1>
      </div>
      <b-row>
        <b-col cols="12">
          <b-form @submit.prevent>
            <b-form-group label="Menu Name" label-for="name">
              <b-form-input
                id="name"
                trim
                placeholder="Header Or..."
                v-model="menuDetails.name"
              />
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button variant="success" @click="sendCreateMenuRequest">
          Create Menu
        </b-button>
      </div>
    </b-card>
    <b-card v-else>
      <h1 class="mb-3">Choose Language</h1>
      <b-row>
        <b-col cols="12">
          <language-picker
            @getLanguage="setLanguage"
            :placeholder="'Please Choose Menu Language First'"
          ></language-picker>
        </b-col>
        <div class="w-100 mt-3 d-flex align-items-center justify-content-end">
          <b-button
            @click="setLanguageIdOnRoute"
            variant="success"
            :disabled="menuDetails.languageId ? false : true"
          >
            Submit Language
          </b-button>
        </div>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
export default {
  title: "Create New Menu",
  data() {
    return {
      isLoading: false,
      menuDetails: {
        isDeleted: false,
        menuId: 0,
        mainId: "",
        name: null,
        seourl: null,
        seoTitle: null,
        seoDescription: null,
        languageId: null,
        createDate: new Date(Date.now()).toISOString(),
      },
    };
  },
  methods: {
    setLanguageIdOnRoute() {
      this.$router.push(`/pages/menu/create/${this.menuDetails.languageId}`);
    },
    setLanguage(language) {
      this.menuDetails.languageId = language.languageId;
    },
    async sendCreateMenuRequest() {
      try {
        this.isLoading = true;
        let _this = this;
        let createNewMenu = new CreateNewMenu(_this);
        createNewMenu.setRequestParamDataObj(_this.menuDetails);
        await createNewMenu.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Operation Was Successful",
                icon: "CheckIcon",
                variant: "success",
                text: "Menu Created Successfully",
              },
            });
            _this.menuDetails = {
              isDeleted: false,
              menuId: 0,
              name: null,
              seourl: null,
              seoTitle: null,
              seoDescription: null,
              languageId: null,
              createDate: new Date(Date.now()).toISOString(),
            };
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BBadge,
    BFormTextarea,
    BOverlay,
    BFormSelect,
    LanguagePicker,
  },
};
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { CreateNewMenu } from "@/libs/Api/Menu";
import LanguagePicker from "@/views/components/utils/LanguagePicker.vue";
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BBadge,
  BFormTextarea,
  BOverlay,
  BFormSelect,
} from "bootstrap-vue";
</script>
